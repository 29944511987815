/**
 * Created by andyosullivan on 15/11/2021.
 */
export const movie_details = [
    {
        "name": "Bridget Jones: Mad About the Boy - Laughs and Tears",
        "movie": "bridget-jones-mad-about-the-boy-laughs-and-tears",
        "subtitle": "Bring some tissues",
        "rating": 4,
        "image_1": {image:"bridgetJones_001.jpg", text:"",alt:""},
        "image_2": {image:"bridgetJones_002.jpg", text:"",alt:""},
        "image_4": {image:"bridgetJones_003.jpg", text:"",alt:""},
        "image_3": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1": "We've seen Bridget Jones: Mad About The Boy, and we're not the same since. This " +
            "is a tearjerker pretending to be comedy, so be prepared to laugh and cry! Mild SPOILERS follow so" +
            " if you don't want to know anything maybe trot off somewhere for a glass of wine.",
        "paragraph_2": () => (
            <span>
                We went to see this movie not because we're particular fans of the either the books or the movies,
                but because it's actually rare these days for a movie like this to be in the cinema. We're glad we did
                - this is a great movie, but as we mentioned it's not just a comedy, it's also sticking the knife into
                our hearts and giving it a good twist. SPOILER ALERT - though you've probably seen this in the trailers
                - Mr. Bridget Jones AKA Mr. Darcy AKA Colin Firth has died since the last movie, and this one shows
                our heroine Bridget trying to manage with life and two kids while also still deeply in grief.
    </span>
        ),
        "paragraph_3":"Don't get us wrong - this is still a comedy, sometimes silly and sometime laugh-out-loud" +
            " but we weren't prepared for all the emotions! It's a better movie for it of course, and good to see" +
            " that they didn't just try to do what the previous movies did.",
        "paragraph_4":"We're not sure which boy the title refers to, but there are several eye-candy men on show" +
            "- Leo Woodall as the younger man, Hugh Grant as the aging scoundrel (that at this stage is more of a" +
            " secret-heart-of-gold friend to Bridget), and Chiwetel Ejiofor as one of her kids' teachers." +
            " All three are great in the movie, with Hugh Grant our favourite - possibly because he" +
            " gets the best lines (apparently a Filthy Bitch is a cocktail! IYKYK).",
        "paragraph_5":"There's also appearances from Bridget's friend gang from the previous movies," +
            " though as before we think James Callis is criminally underused (his role as Gaius Baltar in the " +
            "Battlestar Gallactica reboot is one of our all time favourite tv acting performances).",
        "paragraph_6":"All in all, worth seeing!",
        "paragraph_7":"",
        "paragraph_8":"",
        "director": "Michael Morris",
        "writer":"",
        "main_cast": "Renée Zellweger, Chiwetel Ejiofor, Leo Woodall, Hugh Grant",
        "summary": "Laughs and tears",
        "diversity": [
            {"question":"White guy the main character?","answer": "No","className": "greenBox"},
            {"question":"Mainly male characters?","answer":"No","className": "greenBox"},
            {"question":"Any strong female characters?","answer":"100%","className": "greenBox"},
            {"question":"Is it a diverse cast?","answer":"Yes","className": "greenBox"},
            {"question":"Much LGBTQ+ representation?","answer":"Not really","className": "redBox"}],
        "date": "21/Feb/25",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://bsky.app/profile/andyosullivan.bsky.social"},
        "wifereview": "",
        "kidsreview": "",
        "short": false,
        "image_sources": "https://www.universalpictures.ie/micro/bridget-jones",
    },
    {
        "name": "Gladiator 2 Review - More But Slightly Less",
        "movie": "gladiator-2-review-more-but-slightly-less",
        "subtitle": "Almost as good as the first",
        "rating": 5,
        "image_1": {image:"gladiator_2_001.jpg", text:"",alt:""},
        "image_2": {image:"gladiator_2_002.jpg", text:"We'd watch this guy in anything",alt:""},
        "image_4": {image:"gladiator_2_004.jpg", text:"Denzel doing Denzel things",alt:""},
        "image_3": {image:"gladiator_2_003.jpg", text:"",alt:""},
        "image_5": "",
        "image_6": "",
        "paragraph_1": "We’re back in the Colosseum for our Gladiator 2 review, with some mild SPOILERS so if you " +
            "don’t want to know anything hop in your chariot and head off back to your villa.",
        "paragraph_2": () => (
            <span>
                Gladiator 2 is of course the sequel to one of the most beloved of movies
                so it has a lot to live up to. Does it? In short - yes and no. We loved it,
                our other half didn’t. We thoroughly enjoyed it -
                this is a big spectacle of a movie, and yes while it is a sequel,
                it’s a pleasure to watch a film in a packed cinema that isn’t a superhero movie. It isn't quite as
                good as the first, but we're not sure that even would be possible. The first Gladiator had
                epic battles in the arena with chariots and tigers, and this movie one-ups those battles
                but we're not completely as awed as we were the first time, as it's not the first time.
    </span>
        ),
        "paragraph_4":"There’s been a lot written about Denzel Washington’s character, Macrinus, and " +
            "he’s having an absolute ball. So much that we wonder why they felt they needed so many " +
            "other villains - including the evil twin Emporers who we spend most of the movie wondering " +
            "why someone hasn’t assassinated them yet. Could the story have been better with just Denzel " +
            "as the main adversary? We think so, but we guess as to why he’s not - " +
            "Ripley Scott has turned the amp all the way up to 11 on this one, " +
            "basically taking the first movie and asking himself “how do we make this movie MORE?!”. " +
            "More as in great white sharks swimming around the Colosseum during one of the games! " +
            "We’ll need to do a bit of googling but we're not sure " +
            "they’d have been able to transport sharks back in Roman times but who are we to say!",
        "paragraph_3":"Paul Mescal, who we had never actually seen before on screen, is great. " +
            "With massive boots to fill, he more than makes this new role his own - a brooding soldier played " +
            "with a mixture of apathy and menace. There are a couple of speeches he gives that feel a bit forced, as they obviously try to emulate Russel Crowe’s “what we do in life echoes in eternity” type spiels, " +
            "but overall you feel like you’re watching a movie star delivering in a big movie star part. ",
        "paragraph_6":"SPOILER ALERT! The end of the movie seems a bit rushed but isn’t bad, " +
            "and leaves room for number 3, which we’re guessing won’t be another 20 or so years. " +
            "We didn’t really like the ending of the first film either, with Maximus dying. " +
            "Again while it was understandable for story reasons (and films that want to win Oscars love killing people off), " +
            "we suspect it’s a decision the film makers came to regret, and one that deprived us of a much earlier sequel. ",
        "paragraph_5":"In another What If? we also wonder what the movie would have been like if Pedro Pascal " +
            "was the main star. Mescal is more than great, but so is Pascal - his war weary Roman general " +
            "a fantastic addition. I can understand why they wanted a younger actor to fit in with the timelines, " +
            "but I’d have gone to see Pascal lead the movie either - he elevates anything he's in. We think that Gladiator 2" +
            " is slightly restricted by trying to emulate the first movie so much - hero gets captured, wants revenge for his dead family," +
            " has incredible fights in the arena etc, so Pascal as great as he is, has limited options, story wise.",
        "paragraph_7":"In summary - an epic cinema worthy film, that isn’t quite as good as the original but still better than most movies you’ll see this year.",
        "paragraph_8":"",
        "director": "Ripley Scott",
        "writer":"",
        "main_cast": "Paul Mescal, Denzel Washington, Connie Nielson, Pedro Pascal",
        "summary": "Epic sequel worth seeing",
        "diversity": [
            {"question":"White guy the main character?","answer": "Yep","className": "redBox"},
            {"question":"Mainly male characters?","answer":"Mostly","className": "redBox"},
            {"question":"Any strong female characters?","answer":"Yes, but they kind of only exist to give the men reason for revenge","className": "redBox"},
            {"question":"Is it a diverse cast?","answer":"Yes","className": "greenBox"},
            {"question":"Much LGBTQ+ representation?","answer":"Not really","className": "redBox"}],
        "date": "23/Nov/24",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://bsky.app/profile/andyosullivan.bsky.social"},
        "wifereview": "",
        "kidsreview": "",
        "short": false,
        "image_sources": "https://www.gladiator.movie/",
    },
    {
        "name": "Alien Romulus Review: Jumpy AF",
        "movie": "alien-romulus-review-jumpy-as-f",
        "subtitle": "You probably don't need any popcorn",
        "rating": 5,
        "image_1": {image:"alien002.jpeg", text:"",alt:""},
        "image_4": {image:"alien003.jpeg", text:"",alt:""},
        "image_2": {image:"alien001.jpg", text:"I'm afraid I can't do that",alt:""},
        "image_3": {image:"alien004.jpg", text:"BFG",alt:""},
        "image_5": "",
        "image_6": "",
        "paragraph_1": "We've been going to the cinema to see Alien movies for years -" +
                " from Alien Resurrection with Sigourney Weaver & Winona Ryder, to Alien VS Predator (a bit silly but actually loads of fun)" +
            ", Prometheus (great), Alien Covenant (so-so)" +
                " and now Alien: Romulus. These movies are better in a dark cinema, where everyone else knows" +
                "  what they're in for - a couple of hours of folks being stalked and killed by some pretty" +
               "  cool aliens. Some mild SPOILERS follow so head back to the colony now if you want to ... before it's too late!",
        "paragraph_2": () => (
            <span>
                All Alien movies end up the same way - a group of people being killed one by one on some spaceship by
                 the classic Alien alien, and maybe also some of those bloodcurdlingly scary face hugger things.
                This movie doesn't break from the formula, but it is still a great movie. We know what we're going to get
                - a strong heroine who'll hopefully win by the end, and in between we'll be treated to all sorts of mayhem
                and tension. And boy, is there tension in this movie. I lost count of the number of times I was
                looking through my hands, dreading what was about to happen. One scene has those aforementioned face huggers
                swimming through a flooded room around the feet of folks trapped there, and the characters' terror is matched by our terror watching it. "Get out of there!"
                 we silently scream from the dark cinema!
    </span>
        ),
        "paragraph_4":"Although this movie isn't all that original (apart from MILD SPOILER some frankly crazy stuff towards the end of the movie)" +
            " this is an enjoyable movie and kept us on edge right the way through. Cailee Spaeny is great as our heroine - " +
            "a movie like needs someone interesting in the main role and she fits the bill perfectly. Her character isn't" +
            " a hard-boiled asskicker, her motivations are trying to get her and her android brother Andy (played by David Johnson)" +
            " off their miserable corporation-run planet, and she's a sympathetic figure for folks to root for. David Johnson is" +
            " simply fantastic as the simplistic android and (we're being careful not to give too much away), his journey through" +
            " the movie provides some of the best moments. The Alien movies' androids are always that little bit off-kilter," +
            " and a lovely counterpoint to Star Wars or Star Trek robots, just as the Alien aesthetic of the future - more" +
            " dystopian and industrial than utopian feels just more realistic.",
        "paragraph_3":"",
        "paragraph_6":"One thing we've reflected on after watching the movie is that the actual alien itself-  the xenomorph" +
            " jet black nightmare with another small head inside its mouth just for extra scary points - isn't in the movie that much. Not from a 'let's show the" +
            " monster less' approach, but more I think as the director realised that the face-huggers, and the fear of being" +
            " implanted by them with a mini-xenomorph that'll jump out of your chests is actually much more frightening. It's" +
            " the terror of being taken over and killed from the inside as oppose to the massive scary thing with acid for blood." +
            " Hence our review title - this movie is jumpy AF.",
        "paragraph_5":"",
        "paragraph_7":"One final note - I used to work in a cinema and noticed that when folks watched a really good scary movies, a lot of " +
            "people didn't eat their popcorn. Whether it was because of the tension, or they lost their appetite watching" +
            " gruesome things on the screen, after movies like the Sixth Sense, we'd be picking up full tubs of popcorn" +
            " or nachos in the aisles. Likewise with Alien Romulus - I bought a large popcorn, but didn't put a serious dent in it at all!",
        "paragraph_8":"",
        "director": "Fede Álvarez",
        "writer":"Fede Álvarez & Rodo Sayagues",
        "main_cast": "Cailee Spaeny, David Johnson, Isabela Merced, Archie Renaux",
        "summary": "Great horror and definitely worth a watch on a big screen in a dark cinema.",
        "diversity": [
            {"question":"White guy the main character?","answer": "Nope","className": "greenBox"},
            {"question":"Mainly male characters?","answer":"No, it's not all fellas.","className": "greenBox"},
            {"question":"Any strong female characters?","answer":"Very much so","className": "greenBox"},
            {"question":"Is it a diverse cast?","answer":"Yes","className": "greenBox"},
            {"question":"Much LGBTQ+ representation?","answer":"Not that we could see.","className": "redBox"}],
        "date": "01/Sep/24",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://bsky.app/profile/andyosullivan.bsky.social"},
        "wifereview": "",
        "kidsreview": "",
        "short": false,
        "image_sources": "https://press.disney.co.uk/press-kit/alien:-romulus",
    },
    {
        "name": "Family Movie Night - Signs",
        "movie": "family-movie-night-signs",
        "subtitle": "In our top ten movies of all time",
        "rating": 5,
        "image_2": {image:"signs_002.jpg", text:"",alt:""},
        "image_4": "",
        "image_1": {image:"signs_003.jpg", text:"",alt:""},
        "image_3": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"Welcome to the fourth iteration of our Family Movie Night series, where we review movies, old or new that" +
            " we like to watch with the kids. Mild spoilers follow for Signs, the 2002 M. Night Shymalan sci-fi / scary movie" +
            " but we promise we don't give away the ending!",
        "paragraph_2": () => (
            <span>
        One of our first Family Movie Night reviews was The Sixth Sense (check it out <a href="/movies/family-movie-night-the-sixth-sense"  target="_blank" rel="noopener noreferrer">here)</a> and
                while it may not seem like something to watch with the kids, it was perfect for the teenagers, who to be honest
                had never seen a movie like it before. Signs is from the same director and we think it's just as good.
    </span>
        ),
        "paragraph_3":"It is in some ways a very simple movie - mainly set at a farm in Pennsylvania where" +
            " crop circles appear one night, followed by more strange goings on. There's a dad (Mel Gibson), his kids," +
            " and his brother, and a whole lot of corn. Things move slowly in the movie, but that's one of its strong" +
            " points, it helps build the tension and dread as we all know watching it that something is going to happen" +
            " but it takes it time to get there." ,
        "paragraph_4":"There is also plenty of humour, but it never feels out of place, and generally goes as soon as it came" +
            " - momentary reprieves amongst the growing sense of unease that the family (and us) feel. The setting itself is" +
            " also a mix of comforting and frightening - it's a classic American farm, with a real Field of Dreams feel to it," +
            " but it's also surrounded by tall corn stalks where you just know something is lurking.",
        "paragraph_5":"Mel Gibson is great, it's a restrained performance, but it helps ground the story. This isn't a" +
            " a \"Wake up the president! Def Con 4!\" movie, this is about one family dealing with what may be " +
            "the end of the world. There's very little information given on what's going on in the wider world, so" +
            " the family becomes the viewer's world. Joaquin Phoenix is brilliant aswell, as are the child actors. " +
            "I've been saying \"The water is contaminated\" for years at home and finally the teenagers know why!",
        "paragraph_6":"We'd recommend turning off all the lights for this one, scares are better in the dark, " +
            " but this isn't a horror movie so don't be worried if you're in two minds about it. Yes, folks may" +
            " be hiding behind their hands watching, but there's nothing gruesome or nasty about it" +
            " - it's not that kind of movie. ",
        "paragraph_7":"In summary - it's one of our all time favourites; scary, great quality, great setting, just " +
            "a good movie.",
        "paragraph_8":"",
        "director": "M. Night Shyamalan",
        "writer":"",
        "main_cast": "Mel Gibson, Joaquin Phoenix, Rory Culkin, Abigail Breslin",
        "summary": "One of our top 10 all time movies",
        "diversity": [
            {"question":"White guy the main character?","answer": "Yep","className": "redBox"},
            {"question":"Mainly male characters?","answer":"No, it's not all fellas.","className": "greenBox"},
            {"question":"Any strong female characters?","answer":"The female Sheriff has a small but strong part","className": "greenBox"},
            {"question":"Is it a diverse cast?","answer":"Not really, mainly white folks","className": "redBox"},
            {"question":"Much LGBTQ+ representation?","answer":"Not that we could see.","className": "redBox"}],
        "date": "23/Aug/24",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://bsky.app/profile/andyosullivan.bsky.social"},
        "wifereview": "",
        "kidsreview": "",
        "short": false,
        "image_sources": "https://www.disney.com",
    },
    {
        "name": "Twisters Review - it's Daisy's Story",
        "movie": "twisters-review-daisy-story",
        "subtitle": "Big fun summer blockbuster",
        "rating": 5,
        "image_2": {image:"twisters_daisy.jpg", text:"Sensing a disturbance in the Force",alt:""},
        "image_4": "",
        "image_1": {image:"twisters_5.jpg", text:"",alt:""},
        "image_3": {image:"twisters_8.jpg", text:"Lots of big trucks!",alt:""},
        "image_5": {image:"twisters_director.jpg", text:"",alt:""},
        "image_6": "",
        "paragraph_1":"Twisters is a big fun summer blockbuster and we loved it. Very mild SPOILERS to follow so get to your nearest storm cellar, or empty swimming pool, if you don’t want to know anything about the movie!",
        "paragraph_2": () => (
            <span>:"Our kids usually roll their eyes when we bore them about how
                ‘they don’t make as many good movies as they used to’ but we think we’re right.
                Whether it’s because of Covid, or the rise of streaming, or the over proliferation of Marvel movies,
                there just isn’t as many “do you want to go see” movies anymore.
                When we saw the trailer for Twisters however, we knew we’d go see it and that it would do well.
                Yes it’s a sequel/reboot/whatever but it’s also something rare - a non superhero mainstream
                movie that will appeal to a lot of people. "</span>) ,
        "paragraph_3":"It’s not the most complicated of movies (there’s tornadoes and people chasing them) but it’s " +
            "full of action, characters we can root for, and is never boring. Screenwritten by Mark L. Smith, its story was " +
            "created by Joseph Kosinski who directed Top Gun Maverick, and we’re not surprised - it’s a simple story, " +
            "but there’s plenty of emotion and stakes on show. Directed by Lee Isaac Chung who grew up in Arkansas it’s set " +
            "almost entirely in America’s heartland and like Top Gun Maverick’s setting of Fightertown USA almost " +
            "seems like an idealised dream, where fighter pilots don’t wear helmets on their motorcycles, the USA " +
            "on shown here is full of ruggedly handsome men in large cowboy hats, driving really big trucks." ,
        "paragraph_4":"One thing we didn’t realise before we watched the movie is that one of those handsome men - " +
            "a great Glen Powell, playing Tyler Owens the self named Tornado Wrangler, isn’t the main character - " +
            "the excellent Daisy Edgar-Jones’ character Daisy is. It was probably the Powell-heavy marketing that " +
            "made us think it was another Guy Saves the Day movie, but this is really Edgar-Jones’ movie. She " +
            "plays a genius meteorologist with a knack for understanding tornadoes, who has a fittingly tragic backstory that gets us invested immediately in what happens with her. To emphasise the point, Powell actually says “she is your story, isn’t she?” to a journalist that had been initially following him, and it’s refreshing (and so rare) to see a big action movie where the guy just isn’t automatically the lead. \n" +
            "Powell is fantastic aswell, with real movie star appeal to match Edgar-Jones’ and his ability to " +
            "look like a big rugged cowboy, while also obviously a good actor, shows a bright future. \n" +
            "Anthony Ramos also stars as one of Daisy’s friends and fellow tornado chaser, but to be honest he kind of feels " +
            "like the third wheel, this is obviously the Daisy and Glen show. ",
        "paragraph_5":"",
        "paragraph_6":"All in all, just a great popcorn movie and perfect for the summer. We've seen it with our other half," +
            " and will be going to see it again with the teenager daughters. Yes - they like that Powell is in it, but" +
            " they'll also get to see a female led action movie.",
        "paragraph_7":"",
        "paragraph_8":"",
        "director": "Lee Isaac Chung",
        "writer":"",
        "main_cast": "Daisy Edgar-Jones, Glen Powell, Anthony Ramos",
        "summary": "Big fun summer blockbuster",
        "diversity": [
            {"question":"White guy the main character?","answer": "No, it's Daisy's movie","className": "greenBox"},
            {"question":"Mainly male characters?","answer":"No, it's not all fellas.","className": "greenBox"},
            {"question":"Any strong female characters?","answer":"Very much so, Daisy's Kate is basically a genius, and she chases tornadoes!","className": "greenBox"},
            {"question":"Is it a diverse cast?","answer":"Kind of, it's not just a bunch of white folks, but the two main leads are.","className": "greenBox"},
            {"question":"Much LGBTQ+ representation?","answer":"Somewhat - both Sasha Lane and Katy O’Brian star in the movie, though aren't the main characters","className": "greenBox"}],
        "date": "30/Jul/24",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://bsky.app/profile/andyosullivan.bsky.social"},
        "wifereview": "",
        "kidsreview": "",
        "short": false,
        "image_sources": "https://www.universalpictures.com/movies/twisters",
    },
    {
        "name": "Migration Review",
        "movie": "migration-review",
        "subtitle": "A pointless but fun family movie",
        "rating": 3,
        "image_1": {image:"migration-1.jpg", text:"",alt:""},
        "image_2": {image:"migration-2.jpg", text:"",alt:""},
        "image_4": "",
        "image_3": {image:"migration-3.jpg", text:"",alt:""},
        "image_5": "",
        "image_6": "",
        "paragraph_1":"Migration is a relatively new animated movie from the creators of the Minions movies," +
            " mild SPOILERS follow so leave now if you don't want to know what happens to the ducks!",
        "paragraph_2": () => (
            <span>:"I took the two small kids to see this in the cinema and my wife asked me afterwards" +
            " if it was good. \"Tricky\" I answered - the kids enjoyed it \"Can we watch it again\" from the 5 year old, " +
            " but I thought it was kind of mildly funny, mostly pointless but also just a bit crazy to keep" +
                " the kids entertained."</span>) ,
        "paragraph_3":"Our heroes are a family of ducks, whose (in movie trope alert!) dad is too afraid of danger" +
            " to leave their pond. \"Like the Croods, but with ducks?\" asked the wife. Exactly! Of course, they" +
            " leave the pond and end up on a series of adventures that, in fairness to ducks, may be pretty" +
            " amazing, but it's not exactly a trip to Mordor that we're talking about here. " ,
        "paragraph_6":"One final thing - there's a (for very young kids) scary bit at the start involving a heron" +
            " that had my 5 year old waking up with nightmares, so just watch out for that!",
        "paragraph_4":"There's of course a cute little duckling, an old Uncle Dan duck (played by Danny DeVito!)," +
            " a great Jamaican parrot, and in the most random movie choice ever, an evil Chef who has his own helicopter" +
            " to help him catch ducks which escape his clutches! Not just any helicopter - a huge Chinook style job with" +
            " double rotors! Just sitting on the roof of his restaurant, operated by just him, to just catch some ducks!",
        "paragraph_5":"It's this slightly nuts element that makes the movie more than just another cute animals go " +
            "on an adventure movie, but also kind of shows that there's no real stakes here, so they had to introduce " +
            "a fairly unrealistic villain. A chef with his own Chinook!",
        "paragraph_7":"",
        "paragraph_8":"",
        "director": "Benjamin Renner",
        "writer":"",
        "main_cast": "Elizabeth Banks, Kumail Nanjiani, Awkwafina, Keegan-Michael Key",
        "summary": "A pointless but fun family movie",
        "diversity": [
            {"question":"White guy the main character?","answer": "Nope, em, they're ducks","className": "greenBox"},
            {"question":"Mainly male characters?","answer":"No","className": "greenBox"},
            {"question":"Any strong female characters?","answer":"Very much so.","className": "greenBox"},
            {"question":"Is it a diverse cast?","answer":"Yes, not just a bunch of white folks!","className": "greenBox"},
            {"question":"Much LGBTQ+ representation?","answer":"Not that we could see.","className": "redBox"}],
        "date": "18/Feb/24",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://bsky.app/profile/andyosullivan.bsky.social"},
        "wifereview": "",
        "kidsreview": "",
        "short": false,
        "image_sources": "https://www.universalpictures.com/movies/migration",
    },
    {
        "name": "No One Will Save You Review",
        "movie": "no-one-will-save-you-review",
        "subtitle": "With no dialogue, this is an unusual, frightening, movie",
        "rating": 3,
        "image_1": {image:"NOW-07355_R.jpg", text:"",alt:""},
        "image_2": {image:"NOW-11616_R.jpg", text:"",alt:""},
        "image_4": "",
        "image_3": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"We are privileged to have early access to some shows on Disney+ and when the email came in yesterday" +
            " that we had access to a new movie No One Will Save You, we were initially excited, then troubled when" +
            " we realised it was a sci-fi horror movie. One of the stipulations of early access is that the reviewer" +
            " has to watch the show or movie on their own, away from anybody else - so basically watch a scary movie" +
            " that you know nothing about, on your own, most likely late at night when everyone else is asleep!",
        "paragraph_2": () => (
            <span>:"We took the plunge and spent a slightly uncomfortable hour and a half watching a movie" +
            " that in some ways is quite derivative of other movies, but in others is remarkably innovative. Without" +
            " spoiling anything, this is an alien invasion movie, rather like Signs (one of our all time favourites!)" +
            " but the major twist is that there is no dialogue. We didn't clock it at first - we thought the lack of talking" +
            " was merely at the start to build up the tension and dread, but then we realised that there is just about no" +
            " speaking at all in the entire movie. When you watch, it kind of makes sense - " +
            " it's very much a one-woman show (a mesmerising Kaitlyn Dever as Brynn) or as stated by the director Brian Duffield in the official" +
            " press release \"I wanted the movie to take the lead of a small intimate character study and drop an alien invasion on\n" +
            "top of them\". So it's basically her story - and she's on her own for most of it, so why would she be talking?" +
            " There are moments of real terror when she's trying to be quiet - a lesser movie would have her screaming" +
                " and shouting, this film has her silent and terrified."</span>),
        "paragraph_3":"We didn't like how the movie has gone for the typical 'Grey' alien - big head, bulging eyes type look," +
            " but after pondering on it for a while after watching the movie, we think it doesn't really matter. The movie" +
            " isn't really about the aliens, it's about Brynn and her solitary existence, and why her life" +
            " is the way it is (again, no spoilers!). It's a movie about how she reacts and copes to what's happening, and" +
            " the aliens may as well have been anything - a Scream type killer, or anything else to cause the conflict. We actually" +
            " think it could be a much better movie if there were no aliens, as the central performance is so good it doesn't" +
            " really need them, but that's just an opinion after watching it for the first time." ,
        "paragraph_6":"",
        "paragraph_4":"What did we think overall? We did get major Signs vibes, which we liked, though it's nowhere near as good as that film " +
            " and one major thing we have to note is that on first viewing, we don't really understand the ending!" +
            " We're going to have to watch it again, and maybe wait for reviews by more smarter people to explain it to us!" +
            " This movie is different though, and different is good. We constantly complain about the lack of original movies," +
            " and the default aliens aside, this is a novel, unusual, frightening movie. Kaitlyn Dever is fantastic, leading a movie" +
            " with her expressions and actions and throughout the movie just draws and holds attention. We'd recommend a watch, though perhaps" +
            " not on your own when everyone else in the house is asleep!",
        "paragraph_5":"",
        "paragraph_7":"",
        "paragraph_8":"",
        "director": "Brian Duffield",
        "writer":"Brian Duffield",
        "main_cast": "Kaitlyn Dever",
        "summary": "Scary and disquieting, worth a watch. But maybe not on your own!",
        "diversity": [
            {"question":"White guy the main character?","answer": "Nope","className": "greenBox"},
            {"question":"Mainly male characters?","answer":"No","className": "greenBox"},
            {"question":"Any strong female characters?","answer":"Very much so.","className": "greenBox"},
            {"question":"Is it a diverse cast?","answer":"Not really","className": "redBox"},
            {"question":"Much LGBTQ+ representation?","answer":"Not that we could see.","className": "redBox"}],
        "date": "22/Sep/23",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://bsky.app/profile/andyosullivan.bsky.social"},
        "wifereview": "",
        "kidsreview": "",
        "short": false,
        "image_sources": "https://press.disney.co.uk/",
    },
    {
        "name": "Indiana Jones & The Dial of Destiny Review",
        "movie": "indiana-jones-dial-of-destiny-review",
        "subtitle": "A big fun adventure, we loved it.",
        "rating": 5,
        "image_2": {image:"dialofdestiny007.jpeg", text:"Does he really look 80?!",alt:""},
        "image_1": {image:"dialofdestiny004.jpeg", text:"",alt:""},
        "image_4": "",
        "image_3": {image:"dialofdestiny001.jpeg", text:"Will these goons ever learn to stay away from the magic relics?!",alt:""},
        "image_5": "",
        "image_6": "",
        "paragraph_1": "We've just seen Indiana Jones & the Dial of Destiny and it's much better than we were led to believe. Mild SPOILERS" +
            " follow but not really, so you can probably feel free to read ahead.",
        "paragraph_2": () => (
            <span>:"This is of course the 5th Indiana Jones movie, which may explain why it's under-delivering at the box office" +
            " - people may be sequelled out (we're not entirely sure sequelled is a word!), or maybe they think Ford is just too" +
            " old to play Indiana Jones again. Maybe folks don't like that Spielberg isn't directly this one, we don't know." +
            " However, we're happy to report that this is a great movie, just a fun adventure that kept us entertained right" +
                " the way through."</span>),
        "paragraph_3":"Every good action movie needs a good bad guy and the Dial of Destiny returns to the franchise's" +
            " classic villians, Nazis. Mads Mikkelsen is a steady hand at the Pure Evil Nazi Guy tiller and he doesn't" +
            " really put a foot wrong, he's born to play roles like this. Phoebe Waller-Bridge, who we'd heard of but never" +
            " actually seen in anything is just great as Indy's goddaughter, and more than matches him in attitude and fight." +
            " There is a 'random kid' ,as one my own kids called him, who tags along for most of the adventure, and we kind of wondered" +
            " why he was included but he's not one of those annoying kid characters so we didn't mind it." ,
        "paragraph_6":"One final thought - we took our two older kids and they liked it, so it's not just for the nostalgia folks like us!",
        "paragraph_4":"Is the movie a little silly in places? Yes, of course, but this isn't that unusual for a series like this!" +
            " We're not going to spoil anything about the final third or so of the movie, but just want to mention that" +
            " we loved it, for lots of reason we won't say for the no-spoiling promise! We thought the 4th Indiana Jones" +
            " movie, the Crystal Skull, wasn't the best movie but the Dial of Destiny is better. It's not as good as" +
            " Raiders or the Last Crusade, but let's be honest, what movies are? So we'd recommend not being worried" +
            " about whether it's better or as good as the classic Indy movies, but to enjoy this for what it is" +
            " - a great summer blockbuster, perfect for a night out away from the usual stuff on TV. ",
        "paragraph_5":"",
        "paragraph_7":"",
        "paragraph_8":"",
        "director": "James Mangold",
        "writer":"James Mangold",
        "main_cast": "Harrison Ford, Phoebe Waller-Bridge, Mads Mikkelsen",
        "summary": "A perfect night at the cinema.",
        "diversity": [
            {"question":"White guy the main character?","answer": "Yep.","className": "redBox"},
            {"question":"Mainly male characters?","answer":"Yes.","className": "redBox"},
            {"question":"Any strong female characters?","answer":"Yes, Phoebe Waller-Bridge is easily Indy's equal.","className": "greenBox"},
            {"question":"Is it a diverse cast?","answer":"Somewhat.","className": "greenBox"},
            {"question":"Much LGBTQ+ representation?","answer":"Not that we could see.","className": "redBox"}],
        "date": "14/July/23",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://bsky.app/profile/andyosullivan.bsky.social"},
        "wifereview": "",
        "kidsreview": "",
        "short": false,
        "image_sources": "https://press.disney.co.uk/gallery/indiana-jones-and-the-dial-of-destiny-logo-and-key-art",
    },
    {
        "name": "Extraction 2 Review",
        "movie": "extraction-2-review",
        "subtitle": "Sit back and enjoy pure action",
        "rating": 5,
        "image_1": {image:"tylerrake001.jpeg", text:"Was that my stop?",alt:""},
        "image_2": {image:"tylerrake002.jpeg", text:"Who knew prison yards could be so rowdy?",alt:""},
        "image_3": {image:"tylerrake003.jpeg", text:"F*** off!",alt:""},
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1": "We've just watched Extraction 2, twice, and we absolutely loved it. We won't have any major spoilers" +
            " so feel free to keep reading!",
        "paragraph_2": () => (
            <span>We'll start by admitting that we never watched the first Extraction, and came in not really knowing
            anything about the characters or what had happened before. This may be why we found the first 10 to 15 minutes
            a bit boring as our hero Tyler Rake (classic short action character name!) recovers from whatever crazy heroics
            he was up to in the first movie, and his friends try to cheer him up. So-so we thought, but then he gets
            asked to do a new mission - and queue Action Movie Training Montage! Chris Hemsworth is a big guy and soon
            he's chopping wood and doing pull-ups we know we'd never be able to do,
                and we knew things were about to kick off!"</span>),
        "paragraph_3":"" ,
        "paragraph_6":"It's not just the Thor show however, the cast is small but there's some great characters - Nik & Yaz, the" +
            " brother and sister colleagues of Rake (if by colleagues you mean they also have huge guns and shoot a lot of people)" +
            " and some nicely vicious bad guys who also get a bit of Tragic Backstory attention. We say small cast, but there's also" +
            " a massive cast of random guys who purpose is to get killed by Rake and friends - it does feel a bit John Wick-like " +
            " as he dispatches folks efficiently, all while he's laser focused on his mission. Don't get me wrong though, it's brilliant fun!",
        "paragraph_4":"What happens then is basically the rest of the movie, with a few small breaks for talking and emotional " +
            "backstory type stuff, is relentless, amazing action of the highest quality, where Rake shoots his way through" +
            " the movie with a variety of huge guns, doing his best John Wick meets Arnie impression as he tears through the" +
            " bad guys. The word quality is the most important here - the action is a step above the normal, with (we're presuming)" +
            " a tonne of choreography and planning to pull off the level of action, explosions, fights and shoot-outs we see. We don't" +
            " want to spoil anything but there's been a lot said in the build-up about a 21 minute 'one-shot' scene where it looks like" +
            " the action doesn't let up for 21 minutes straight. It's pretty incredible to watch, and why we watched the movie twice" +
            " in two days, so we could see it again. ",
        "paragraph_5":"Hemsworth is fantastic as the classic Hero With a Tragic Backstory, and he brings a physicality to " +
            " the role that really sells it. He's gruff, growling at bad-guys to 'F** off' before he shoots them when they" +
            " don't, and he doesn't ever not be serious. We love this seriousness, there's nothing knowingly comic about him," +
            " he's not cracking one-liners or trying to be funny, he's a job to do and he's doing it. We really didn't like" +
            " what Marvel did with Thor - basically turning him into a comic character (Fat Thor! Really?!) and we're" +
            " delighted to see Hemsworth back to a serious role.",
        "paragraph_7":"",
        "paragraph_8":"",
        "director": "Sam Hargrave",
        "writer":"Joe Russo",
        "main_cast": "Chris Hemsworth, Golshifteh Farahani, Adam Bessa, Tinatin Dalakishvili",
        "summary": "Just an awesome top-tier action movie.",
        "diversity": [
            {"question":"White guy the main character?","answer": "Yep.","className": "redBox"},
            {"question":"Mainly male characters?","answer":"No.","className": "greenBox"},
            {"question":"Any strong female characters?","answer":"Yes, Golshifteh Farahani and Tinatin Dalakishvili are great.","className": "greenBox"},
            {"question":"Is it a diverse cast?","answer":"Somewhat.","className": "greenBox"},
            {"question":"Much LGBTQ+ representation?","answer":"Not that we could see.","className": "redBox"}],
        "date": "22/Jun/23",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://bsky.app/profile/andyosullivan.bsky.social"},
        "wifereview": "",
        "kidsreview": "",
        "short": false,
        "image_sources": "https://www.netflix.com/tudum/articles/extraction-2-release-date-photos",
    },
    {
        "name": "Family Movie Night: Matilda the Musical",
        "movie": "family-movie-night-matilda-the-musical",
        "subtitle": "An absolute treat",
        "rating": 5,
        "image_1": {image:"matilda_1.jpeg", text:"Someone go check if that child is alive",alt:""},
        "image_2": {image:"matilda_4.jpeg", text:"Sometimes it's ok to be a little bit naughty",alt:""},
        "image_3": {image:"matilda_3.jpeg", text:"007",alt:""},
        "image_4": {image:"matilda_2.jpeg", text:"Parents of the Year",alt:""},
        "image_5": "",
        "image_6": "",
        "paragraph_1": "Welcome to the latest in our Family Movie Night Reviews series, where we watched the recent" +
            " Matilda the Musical Movie with our two smaller kids, and it is just great. SPOILERS! follow so if" +
            " you haven't seen it yet, you've been warned!",
        "paragraph_2": () => (
            <span>Matilda the Musical is a proper movie, not a recording of a theatre musical or anything like that," +
            and yes it's based on the same Roald Dahl book. It's an absolute treat, similar enough to the book" +
            and the original Danny DeVito movie for comfort and nostalgia, but with enough changes and additions" +
            to make it its own new thing. The songs were written by Tim Minchin, the actor / comedian / lots of other things,
                and they suit the theme - they're funny, irreverent and (pun intended)
                just a little bit naughty - and" +don't seem forced at any stage. My wife turned around during the movie and said incredulously,
                "Is that Emma Thompson?!" and yes it is! She has a total ball playing Miss. Trunchbull, the evil headmistress,
                really relishing the role and giving it her all. When she shotputs the little girl with pigtails over
                the school wall she casually says,"someone check if that child is still alive" and is disappointed
                when the unfortunate girl isn't! It's the sheer evil and ridiculousness of a headmistress being so utterly awful that was
                always a huge part of Matilta's fun, and it's all on show here.</span>),
        "paragraph_3":"" ,
        "paragraph_6":"All in all, this is just a fantastic movie, which kept both kids and both parents watching it" +
            " all the way through, though perhaps it could be a bit scary for younger kids; again mainly thanks to Emma Thompson's" +
            " performance of Agatha Trunchbull. In a time where we'd probably rather see original movies rather than" +
            " things being re-booted ot sequeled out of existence, this was definitely worth the reimagining.",
        "paragraph_4":"Matilda herself is played brilliantly by Alisha Weir, and unlike the book and previous movie, seems" +
            " to take a little more satisfaction in doling out revenge that her predecessors. This fits in with this movie" +
            " however as it is a little bit darker, and adds a similarly dark story-within-a-story about an acrobat" +
            " and a famous escapologist that isn't in the book but that runs through this film. We don't want to spoil what" +
            " exactly it's about, but trust us that it's one of the best parts. Miss Honey is played by Lashana Lynch" +
            " and she is wonderful, although we now see her as 007 after the latest Bond movie so half expected a silenced" +
            " automatic to be produced at any moment! Matilda's dad and mam are also played with sheer abandon by Stephen Graham" +
            " and Andrea Riseborough, again playing just classic Roald Dahl despicable parents. We remembered from the theatrical" +
            " musical version the running \"I'm not a boy, I'm a girl!\" joke so it was good to see that kept in.",
        "paragraph_5":"",
        "paragraph_7":"",
        "paragraph_8":"",
        "director": "Matthew Warchus",
        "writer":"Dennis Kelly",
        "main_cast": "Emma Thompson, Alisha Weir, Lashana Lynch, Stephen Graham, Andrea Riseborough",
        "summary": "A joy of a movie, different enough from the original to be worth watching.",
        "diversity": [
            {"question":"White guy the main character?","answer": "No.","className": "greenBox"},
            {"question":"Mainly male characters?","answer":"No.","className": "greenBox"},
            {"question":"Any strong female characters?","answer":"Definitely!","className": "greenBox"},
            {"question":"Is it a diverse cast?","answer":"Somewhat.","className": "greenBox"},
            {"question":"Much LGBTQ+ representation?","answer":"Not that we could see.","className": "redBox"}],
        "date": "29/May/23",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://bsky.app/profile/andyosullivan.bsky.social"},
        "wifereview": "",
        "kidsreview": "",
        "short": false,
        "image_sources": "https://www.disney.com/",
    },
    {
        "name": "White Men Can't Jump Review",
        "movie": "white-men-cant-jump-review",
        "subtitle": "A lot of fun.",
        "rating": 4,
        "image_1": {image:"WMCJ-15190.jpg", text:"",alt:""},
        "image_2": {image:"WMCJ-19552.jpg", text:"Sinqua Wells as Kamal",alt:""},
        "image_3": {image:"wmcj_2.jpg", text:"Laura Harrier as Tatiana",alt:""},
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1": "White Men Can't Jump is out on the 19th May on Disney+ (or Hulu depending on where you are) " +
            " and is the first movie or tv show that this little startup site has gotten to see before it came out!" +
            " Luckily it didn't disappoint, this is a good movie - fun, colorful, and kept us watching right until the end. " +
            " We've no spoilers in this review, so feel free to read onwards in safety!",
        "paragraph_2": () => (
            <span>:This movie is a remake of the 1992 movie of the same name starring Wesley Snipes and Woody Harrelson and we have to admit
            that we can barely remember watching it, so we went into this movie with almost no context, history or nostalgia
            from the original. We also must confess to never hearing of Jack Harlow - who plays Jeremy, the white guy in the
            story. Harlow is a rapper, and once we read who he was, we got onto Spotify, and yes recognised some of his songs -
            but again, we'd no preconceived notions or ideas about him when we watched the movie
                for the first time.</span>),
        "paragraph_3":"Harlow is " +
            " great however, playing Jeremy as a mix of comedian and injured soul, wise-cracking his way through the film" +
            " while also making us care about what happens to him. Likewise with his partner-in-hustling, Sinqua Wells as " +
            "Kamal, a former basketball prospect who never made it - for reasons we won't spoil - is a great foil to Jeremy, " +
            "acting more of a straight role, but also injecting a lot of passion and emotion. The banter and jokes never stop, the chatting between Harlow and Wells a mixture of getting to " +
            " know each other and mercilessly insulting each other over and over. Together they are the heart of the" +
            " movie, and we really started enjoying it after the obligatory intros to the characters, when they start" +
            " playing and hustling together." ,
        "paragraph_6":"There are other supporting characters - Vince Staples & Myles Bullock for more comedy relief, Laura Harrier and Teyana Taylor " +
            " as Jeremy's and Kamal's girlfriend and wife, and the late Lance Reddick (John Wick, Bosch, The Wire) is great as Kamal's dad - but this" +
            " movie is all about the two main characters, with everyone else kind of orbiting them to help move the plot along. Both characters" +
            " have their issues and we were surprised with how much heart the movie ended up having - while not over the top, there's " +
            " enough depth to the movie to make it more than just a long music video.",
        "paragraph_4":"White Men Can't Jump is directed by Calmatic, mainly known for directing music videos and commercials," +
            " and we think this movie shows those influences. This is a vibrant, colorful movie - it's not grimy and dirty, " +
            " or trying to convene any sort of grim reality, it's a beautifully shot film that looks great even on the small screen." +
            " We don't know why it didn't get a cinematic release and it's a pity, it's a feel-good funny movie that probably" +
            " would have done well, especially in a time where a lot of folks could do with something fun to watch. ",
        "paragraph_5":"",
        "paragraph_7":"One final note - make sure you don't punch a guy who's friends have a flamethrower!",
        "paragraph_8":"",
        "director": "Calmatic",
        "main_cast": "Jack Harlow, Sinqua Walls, Teyana Taylor, Laura Harrier, Vince Staples, Myles Bullock & Lance Reddick",
        "summary": "A fun colorful movie with plenty of jokes, we liked it a lot more than we thought we would.",
        "diversity": [
            {"question":"White guy the main character?","answer": "No.","className": "greenBox"},
            {"question":"Mainly male characters?","answer":"Yes.","className": "redBox"},
            {"question":"Any strong female characters?","answer":"Yes, both Laura Harrier and Teyana Taylor are great.","className": "greenBox"},
            {"question":"Is it a diverse cast?","answer":"Definitely.","className": "greenBox"},
            {"question":"Much LGBTQ+ representation?","answer":"Not that we could see.","className": "redBox"}],
        "date": "18/May/23",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://bsky.app/profile/andyosullivan.bsky.social"},
        "wifereview": "",
        "kidsreview": "",
        "short": false,
        "image_sources": "https://www.disney.com/",
    },
    {
        "name": "Family Movie Night: Indiana Jones & The Last Crusade",
        "movie": "family-movie-night-indiana-jones-last-crusade",
        "subtitle": "An eternal classic",
        "rating": 5,
        "image_1": {image:"indianajones.jpg", text:"",alt:""},
        "image_2": "",
        "image_3": "",
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"Week two of our new Family Movie Night Review series and our two teenagers surprisingly chose Indiana Jones" +
            " and the Last Crusade, a movie so beloved and famous that doing a review of it is kind of pointless! SPOILERS follow," +
            " but if you actually haven't seen this yet, what underground bunker have you been living in for the last" +
            " 30 years?! ",
        "paragraph_2": () => (
            <span>The Last Crusade has it all - great action, Nazis as the bad guys (always make the best/worst bad guys), " +
            Harrison Ford in top form, the late River Phoenix as a young Indy in a great intro, Denholm Elliott as " +
            Marcus "Got lost in his own library" Brody, Alison Doody as the love interest slash surprise Nazi, John Rhys-Davis returning" +
            as Sallah, a 600 year old knight ("He chose poorly") and more!
                It's a funny movie aswell, plenty of jokes to go with the whip cracking and jumping onto "
                "army tanks."</span>),
        "paragraph_3":"Best of all though, it has the incredible extra bonus of Sean Connery playing Indiana's dad," +
            " and he is wonderful. A mix of bumbling professor, sage father figure and just a joy to watch interact with" +
            " Harrison Ford, he's probably why this is our favourite in the series. Acting as a great foil to Indiana, his" +
            " inclusion elevates the movie and the story to more than just another 1930s Indy adventure." ,
        "paragraph_5":"What did the kids think? Some quotes,\"It's actually funny\", \"I liked the bit with his dad\" (see, Sean Connery!)," +
            " but \"I didn't like the bit with the rats!\"",
        "paragraph_4":"",
        "paragraph_6":"That's pretty much all of the review, there's not much more to say that you probably don't know already!" +
            " Come back soon for the next Family Movie Night Review, negotiations are continuing on what to pick, current favourite" +
            " is Groundhog Day!",
        "paragraph_7":"",
        "paragraph_8":"",
        "director": "Steven Spielberg",
        "main_cast": "Harrison Ford, Sean Connery, River Phoenix, Alison Doody",
        "summary": "An all time classic, we'll be re-watching this one forever.",
        "diversity": [
            {"question":"White guy the main character?","answer": "Yes.","className": "redBox"},
            {"question":"Mainly male characters?","answer":"Yes.","className": "redBox"},
            {"question":"Any strong female characters?","answer":"Yes, Alison Doody is a good heroine/villain character.","className": "greenBox"},
            {"question":"Is it a diverse cast?","answer":"Not really.","className": "redBox"},
            {"question":"Much LGBTQ+ representation?","answer":"Not that we could see.","className": "redBox"}],
        "date": "15/May/23",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://bsky.app/profile/andyosullivan.bsky.social"},
        "wifereview": "",
        "kidsreview": "",
        "short": false,
        "image_sources": "https://tv.apple.com/"
    },
    {
        "name": "Family Movie Night: The Sixth Sense",
        "movie": "family-movie-night-the-sixth-sense",
        "subtitle": "A bit scary, but wonderfully made",
        "rating": 5,
        "image_1": {image:"sixthsense.jpeg", text:"",alt:""},
        "image_2": {image:"sixthsense2.jpeg", text:"I see great acting",alt:"A man and child look at something with a map of the world behind them"},
        "image_3": "",
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"We're starting a new review series here on NOadsHERE - Family Movie Night! We recently " +
            "asked our older kids if they'd like to watch a movie with us of a Saturday night and to our surprise," +
            " they agreed! So each week after we watch a movie, we'll post a review here aswell. First up was" +
            " the Sixth Sense - we won't spoil this one, so feel free to keep reading if, however unlikely, you haven't" +
            " seen this one!",
        "paragraph_2": () => (
            <span>:"I used to work in a cinema and the projectionists would often descend from their ivory tower" +
            " in the evening when we were cleaning up - to ask if anyone wanted to watch a new movie reel they'd just gotten in." +
            " One night they asked if we wanted to watch the latest Bruce Willis movie, and we said sure, we love action movies!" +
            " We'd never heard of the Sixth Sense, so needless to say we weren't mentally prepared to watch a scary movie, especially at 1am in a deserted cinema, so we'll " +
            " never forget the first time we watched it!"</span>),
        "paragraph_3":"I actually hadn't seen the movie in about 20 years (it came out in 1999) and I wondered was I looking" +
            " back with rose-tinted glasses, but it's as good as I remembered. The Sixth Sense is famous as M. Night Shyamalan's first movie, and" +
            " it features the unforgettable \"I see dead people!\" line but it's much more than that. It's a beautifully" +
            " constructed and filmed movie, with a simply great, slowly revealing, story about a child psychologist (Bruce Willis)" +
            " tying to help a troubled child (Hayley Joel Osment). We often" +
            " grumble here about the lack of good original movies, be it because of Covid, or the obsession with superhero or" +
            " franchise movies, so re-watching the Sixth Sense was a pleasure. It's a movie that gets better each time you watch it" +
            " - you will notice details you didn't before, and even though I knew when the jumpy bits we're coming, I still jumped!" ,
        "paragraph_5":"More importantly - what did the kids think of it? Both thought it was good, one thought it was sad, and one was slightly maddened" +
            " by the ending!",
        "paragraph_4":"The acting is outstanding, with Bruce Willis playing a very reserved but also somehow emotional role," +
            " far different from his Die Hard persona, Osment as the child, Cole, is just one of those once-in-a-lifetime castings" +
            " and Toni Collette as his mother is also just wonderful. Both Collete and Osment were nominated for Oscars, and the film" +
            " had four other nominations, including Best Film and Best Director. All in all, just a great great movie.",
        "paragraph_6":"",
        "paragraph_7":"",
        "paragraph_8":"",
        "director": "M. Night Shyamalan",
        "main_cast": "Bruce Willis, Hayley Joel Osment, Toni Collette ",
        "summary": "A good choice for our first family movie night in a while.",
        "diversity": [
            {"question":"White guy the main character?","answer": "Yes.","className": "redBox"},
            {"question":"Mainly male characters?","answer":"Two of three leads are male.","className": "redBox"},
            {"question":"Any strong female characters?","answer":"Yes, Toni Collette is fantastic.","className": "greenBox"},
            {"question":"Is it a diverse cast?","answer":"Not really.","className": "redBox"},
            {"question":"Much LGBTQ+ representation?","answer":"Not that we could see.","className": "redBox"}],
        "date": "28/April/23",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://bsky.app/profile/andyosullivan.bsky.social"},
        "wifereview": "",
        "kidsreview": "",
        "short": false,
        "image_sources": "https://www.disneyplus.com/"
    },
    {
        "name": "Super Mario Bros. Movie Review",
        "movie": "super-mario-bros-review",
        "subtitle": "The kids will love it, and that's probably all you need to know.",
        "rating": 2,
        "image_1": {image:"mario3.jpeg", text:"",alt:""},
        "image_2": {image:"mario1.jpeg", text:"",alt:""},
        "image_3": {image:"mario2.jpeg", text:"",alt:""},
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"Reviewing this movie is probably a bit pointless - it's been out a few weeks and is on an absolute tear," +
            " earning bucketloads of cash all over the world. But, as Edna Mode said in a much better animated movie, \"yet, here we are\"." +
            " SPOILERS! follow, so if you haven't seen the movie, maybe don't, but don't read ahead if you're going to.",
        "paragraph_2": () => (
        <span>:"We'll start off by saying that our kids (8 and 5) loved this movie, and let's be honest, that's probably" +
            " all you need to know. Yes - our wife fell asleep for most of it, and yes we've seen better scripts and storylines" +
            " in just about any other movie we've ever seen, but the kids laughed and were entranced the whole way along. "</span>),
        "paragraph_4":"Super Mario Bros is the ultimate example of modern film-making - taking a well known brand and making" +
            " a movie out of it, instead of let's say, trying to create something original. It's made by Illumination, who make" +
            " the excellent Minions movies, but this film is sadly nowhere as good. It's bright and colorful and full of jokes, " +
            "which to be honest did make us laugh out loud on occasion, but it kind of feels like it's paint by numbers, " +
            "a big colorful movie where nothing important actually happens. We get the feeling that whatever story they came up doesn't " +
            "really matter - as long as it's not too bad, kids are going to just love it for all the crazy characters and " +
            "mayhem on screen. Likewise with the voice cast - we don't know how much Chris Pratt got for the movie, but" +
            " we suspect it's a lot, and he's there to have his name on the posters to get the parents in. It doesn't sound like" +
            " him, and the character of Mario is so bland it could be anyone voicing it.",
        "paragraph_6":"",
        "paragraph_3":"",
        "paragraph_5":"",
        "paragraph_7":"",
        "paragraph_8":"",
        "director": "Aaron Horvath, Michael Jelenic",
        "main_cast": "Chris Pratt, Anya Taylor-Joy, Charlie Day, Jack Black",
        "summary": "The kids will love it, you may get to have a nice nap.",
        "diversity": [
            {"question":"White guy the main character?","answer": "Yes.","className": "redBox"},
            {"question":"Mainly male characters?","answer":"Yes.","className": "redBox"},
            {"question":"Any strong female characters?","answer":"The Princess is pretty bad-ass","className": "greenBox"},
            {"question":"Is it a diverse cast?","answer":"Not really.","className": "redBox"},
            {"question":"Much LGBTQ+ representation?","answer":"Not that we could see.","className": "redBox"}],
        "date": "28/April/23",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://bsky.app/profile/andyosullivan.bsky.social"},
        "wifereview": "",
        "kidsreview": "",
        "short": false,
        "image_sources": "https://www.universalpictures.com/movies/the-super-mario-bros-movie"
    },
    {
        "name": "An Cailín Ciúin (The Quiet Girl) Review",
        "movie": "an-cailin-ciuin-review",
        "subtitle": "Beautiful & haunting, it will stay with you.",
        "rating": 5,
        "image_1": {image:"ancailinciuin1.png", text:"Catherine Clinch as Cáit",alt:"A young girl in an old fashioned dress, with blurred woods behind her"},
        "image_2": {image:"ancailinciuin2.jpeg", text:"An Cailín Ciúin is beautifully shot",alt:"A man and girl walk down a farm lane holding metal pails."},
        "image_3": {image:"ancailinciuin3.jpeg", text:"A story about families, grief, love and more",alt:"A young girl stands beside an open door of a yellow car, with an older woman on her hunkers beside her."},
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"An Cailín Ciúin (The Quiet Girl) is an extraordinary movie that will stay with you long after you've watched it.",
        "paragraph_2": () => (
        <span>:"Filmed almost entirely in the Irish language, An Cailín Ciúin (The Quiet Girl) is based on an English language short story 'Foster' by Claire Keegan," +
            " about a neglected young girl who is sent to stay with relations for the summer. We don't want to spoil anything about" +
            " the movie, so we won't discuss any more plot details but we can however mention how beautifully acted and filmed this film is." +
            " Newcomer Catherine Clinch plays Cáit, the quiet girl in question, in a role that sees her in almost all the scenes in" +
            " the movie. She commands attention and draws you into her story, quietly and carefully, and is surrounded by the similarly " +
            " restrained but great performances of the adults in the movie."</span>),
        "paragraph_4":"It is filmed exquisitely, each scene and shot obviously well-crafted and thought out, and it made us consider" +
            " how so many other films are not made as well as this, even though they may have much larger budgets. An Cailín Ciúin " +
            "has only a few settings but makes the most of them, just adding to the impact of the story itself.",
        "paragraph_6":"On the language - don't be put off by the fact it is in Irish, with subtitles it is easily followed, and if like us you " +
            "speak 'Dublin' Irish, the Irish itself is understandable and not the perfect-yet-confusing western dialects " +
            "we can struggle to understand on the Nuacht (Irish news). Caithfimid cúpla rudaí a rá as Gaeilge - scannán iontach tábhactach é seo," +
            " agus ag dhéanamh níos mó airgead ná scannán as Gaeilge roimhe seo. Fiú amhain mura bhfuil sé an saghas scannán" +
            " a féachann tú ar, déan iarracht é a fheicáil.",
        "paragraph_3":"",
        "paragraph_5":"Without spoiling anything, this movie is about family, grief, love and more. As the parent of a girl a similar age" +
            " to Cáit it definitely hit us emotionally, and made us think about our own relationships and parenting styles. Set in 1981 in Ireland," +
            " we also recognise the world we grew up in, which for us anyway, only added to the deeper meaning and affect of the story.",
        "paragraph_7":"",
        "paragraph_8":"",
        "director": "Colm Bairéad",
        "main_cast": "Catherine Clinch, Carrie Crowley, Andrew Bennett, Michael Patric",
        "summary": "A gem of a movie, it's beautifully made and acted.",
        "diversity": [
            {"question":"White guy the main character?","answer": "No.","className": "greenBox"},
            {"question":"Mainly male characters?","answer":"No.","className": "greenBox"},
            {"question":"Any strong female characters?","answer":"Very much so.","className": "greenBox"},
            {"question":"Is it a diverse cast?","answer":"Not really.","className": "redBox"},
            {"question":"Much LGBTQ+ representation?","answer":"Not that we could see.","className": "redBox"}],
        "date": "18/September/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://bsky.app/profile/andyosullivan.bsky.social"},
        "wifereview": "",
        "kidsreview": "",
        "short": false,
        "image_sources": "https://www.breakoutpictures.com/page/press-detail-8"
    },
    {
        "name": "Prey Review",
        "movie": "prey-review",
        "rating": 5,
        "subtitle": "One of the best action movies we've seen in years.",
        "image_1": {image:"prey3.jpg", text:"Amber Midthunder as Naru",alt:"Amber Midthunder as Naru"},
        "image_2": {image:"prey1.jpg", text:"Duck!",alt:"Native American warrior with alien targetting lights on his forehead"},
        "image_3": {image:"prey2.jpg", text:"Let's go!",alt:"Native Americans hollering"},
        "image_4": {image:"preyPoster.jpg", text:"We just had to show this, so cool",alt:"Native American with green-blood stained tomahawk facing a Predator"},
        "image_5": "",
        "image_6": "",
        "paragraph_1":"Prey is an incredible action movie, with a fantastic lead performance by Amber Midthunder and after Top Gun," +
            " our favourite movie of the year so far.",
        "paragraph_2": () => (
        <span>:"Prey is a prequel to the classic action movie Predator, which saw Arnold Schwarzenegger and friends go on" +
            " a rescue mission in the jungle, only to be hunted by an alien visitor. There have been several sequels to Predator, " +
            "of varying quality, but this is the first prequel. Set in the 1700s in the Great Plains of North America, this movie" +
            " sees a Predator hunting Native American Comanche folks, and it is brilliant."</span>),
        "paragraph_3":"If this movie had been made decades ago, it probably would have been exploitative, with white actors playing" +
            " the main roles, but here actors with Native backgrounds are cast - the lead actress is Amber Midthunder, a member of the Fort Peck " +
            "Sioux Tribe. And regardless of her ethnicity, she is perfect in this role - a underestimated woman, whom her people " +
            "do not think is capable of hunting, taking the fight to the alien hunter using her bravery, ingenuity and skills.",
        "paragraph_4":"This site consistently laments the 'Lets put a white guy as the hero' approach to pretty much every action movie " +
            "that is made. This film however is the antidote, with Midthunder absolutely being the core and heart of the story, and" +
            " like with the original Predator you just know she's going to end up kicking that alien's a**! We hope to see her again" +
            " in more movies, action or otherwise, and we're currently trying to convince our daughters to watch this movie as " +
            "there is such a strong female role model on show.",
        "paragraph_5":"We mention that we need to convince, as this sort of movie kind of has a bad reputation, but in this case it's so undeserved." +
            " Trachtenberg has made a beautiful movie, with a great story and good pacing - when we compare it to the recent Gray Man on Netflix," +
            " which cost a fortune but is a bit of mess all round, Prey is a much better movie. Prey is visually gorgeous - with the most being" +
            " made of the unspoiled wilderness of the 1700s. There is something refreshing about an action movie taking its time to show " +
            "its beautiful locations, and not relying on dark warehouses or carnival parades in crowded cities to make their scenes look nice or standout." +
            " Some movies think that action needs to happen as a Mardi-Gras goes past - here we get action in nature, and it is so more impactful.",
        "paragraph_6":"We also have to mention the dog - every action movie should have a dog that knows what to do with a few hand signals.",
        "paragraph_7":"",
        "paragraph_8":"",
        "director": "Dan Trachtenberg (10 Cloverfield Lane)",
        "main_cast": "Amber Midthunder, Dakota Beavers, Dane DiLiegro",
        "summary": "One of the best movies of the year, a visual treat that doesn't disappoint.",
        "diversity": [
            {"question":"White guy the main character?","answer": "NOPE!!","className": "greenBox"},
            {"question":"Mainly male characters?","answer":"No","className": "greenBox"},
            {"question":"Any strong female characters?","answer":"HELL YES!","className": "greenBox"},
            {"question":"Is it a diverse cast?","answer":"Very much so.","className": "greenBox"},
            {"question":"Much LGBTQ+ representation?","answer":"Not that we could see.","className": "redBox"}],
        "date": "07/August/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://bsky.app/profile/andyosullivan.bsky.social"},
        "wifereview": "",
        "kidsreview": "",
        "short": false,
        "image_sources": "https://press.disney.co.uk/press-kit/prey"
    },
    {
        "name": "Gray Man Review",
        "movie": "gray-man-review",
        "subtitle": "Disappointing.",
        "rating": 3,
        "image_1": {image:"grayman.jpg", text:"Your Lordship!",alt:"Regé-Jean Page looking dapper"},
        "image_2": {image:"grayman1.jpeg", text:"Did I just miss my stop?",alt:"Ryan Gosling hanging out a tram with a gun looking confused"},
        "image_4": {image:"grayman3.jpeg", text:"What do you mean Daniel isn't in this one?",alt:"Ana de Armas"},
        "image_3": {image:"grayman4.jpeg", text:"I moustache you a question",alt:"Chris Evan with a rather spiffing moustache"},
        "image_5": "",
        "image_6": "",
        "paragraph_1":"We were looking forward to Gray Man, the new Netflix action movie, mainly due to the big stars - Ryan Gosling," +
            " Chris Evans, Ana de Armas, Regé-Jean Page and more - and the fact it was directed by" +
            " the Russo brothers, who made one of our all-time favourite action movies, Captain America 2: The Winter Soldier. Unfortunately" +
            " however, this movie is a disappointment, a glossy expensive action-fest that seems to think that all it needs" +
            " to succeed is lots of action scenes in lots of different countries.",
        "paragraph_2": () => (
        <span>:"We've read that Netflix views this movie as a potential franchise starter, in the same league " +
            "as James Bond or Jason Bourne - and the influence of both can be seen throughout this movie, but the " +
            "sum isn't greater than the parts. The action scenes in Gray Man are generally completely over the top" +
            " - spilling out of a crowded nightclub in Bangkok into a kind of fireworks display, a huge gun battle in a" +
            " park in Prague, a tram-based scene in Vienna with some surprisingly cheap looking CGI, and more. We found a lot" +
            " of these scenes too confusing, the need for spending all that Netflix money unnecessarily on show."</span>),
        "paragraph_4":"We don't like writing bad things about good actors here on NOadsHERE, but the obvious attempt " +
            "to make Ryan Gosling the new Jason Bourne hasn't worked either. Matt Damon played Bourne as a mix of impassiveness" +
            " and determination but also vulnerabilty and confusion, as he wrestled with his past and his troubled memories." +
            " Gosling however, who we're presuming was meant to be a similar can't-be-fazed-killing-machine comes across" +
            " more as merely being slightly bemused by events. In that park scene in Prague, he's handcuffed to a bench, as local police and" +
            " the bad guys engage in a massive battle - Gosling looks like he's being inconvenienced and nothing more." +
            " His fight scenes don't seem that impressive either, we're fairly sure Bourne would make short work of him.",
        "paragraph_5":"What did we like about the movie? Some other reviews have criticised Chris Evans, who's playing" +
            " another killing machine but with no morals like our hero, but we loved him. He's clearly having an absolute" +
            " ball playing the bad guy after years of being Mr. Perfect in Marvel movies, even if his dodgy moustache" +
            " is definitely a 'let's give the bad guy some facial hair so we know he's bad' trope. There's nothing redeeming about his character however" +
            " - there's no real shades of gray in this movie (pun intended) - so there's no real character in there, just a lot" +
            " of killing people and fun. ",
        "paragraph_6":"Ana de Armas is also good, showing again after the last Bond movie that she can be great at action," +
            " but as we watched we asked ourselves (like we do in most movies we watch) - why couldn't she have been the" +
            " hero? It was reported recently that Jessica Alba said that Marvel movies are still very 'caucasian' - and this" +
            " movie is no different. At the meetings before they make movies like this, do they have a list of famous" +
            " white guys who they pick from? Imagine instead if Ana was the star of the movie - that she was the " +
            " deadly-but-kind assassin with a tragic backstory but impressive ability to look cool even when" +
            " a tram is exploding behind her.",
        "paragraph_7":"Regé-Jean Page shows up as another bad guy, and he also seems to be enjoying the chance to be" +
            " a nasty piece of work! The movie is obviously designed to have sequels we so we'd expect him to show" +
            " back up again, probably with greater screen time. Though again, we had to wonder - what if he had been" +
            " the Gray Man, not Gosling! We suspect the movie could have been better with some bolder casting choices, but" +
            " maybe we're foolish and no amount of actor-switching would cover over the weak script.",
        "paragraph_3":"The first couple of" +
            " Jason Bourne movies were masterclasses in how to make 'realistic' action movies - pared down fights, clever action, and" +
            " very much a real-world feel. The Winter Soldier movie, albiet a Marvel blockbuster, also had a more realistic" +
            " feel to it, with some great, clear, action scenes - Captain America jumped on in the lift, Nick Fury attacked in his car and more." +
            " Gray Man however feels like a series of frantic set pieces put together to make a movie, with very little story to " +
            "hold it all together.",
        "paragraph_8":"",
        "director": "Anthony & Joe Russo (Avengers Infinity War & Avengers Endgame)",
        "main_cast": "Ryan Gosling, Chris Evans, Ana de Armas, Regé-Jean Page, Billy Bob Thorton, Jessica Henwick",
        "summary": "It looks great, with some good action scenes, but overall it's mediocre.",
        "diversity": [
            {"question":"White guy the main character?","answer": "Yep, of course.","className": "redBox"},
            {"question":"Mainly male characters?","answer":"Yep.","className": "redBox"},
            {"question":"Any strong female characters?","answer":"Yes - Ana de Armas","className": "greenBox"},
            {"question":"Is it a diverse cast?","answer":"Sort of.","className": "greenBox"},
            {"question":"Much LGBTQ+ representation?","answer":"Not that we could see.","className": "redBox"}],
        "date": "25/July/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://bsky.app/profile/andyosullivan.bsky.social"},
        "wifereview": "",
        "kidsreview": "",
        "short": false,
        "image_sources": "https://www.netflix.com/tudum/articles/the-gray-man-netflix-new-photos"
    },
    {
        "name": "Top Gun Maverick Review",
        "movie": "top-gun-maverick",
        "subtitle": "A perfect movie, it's simply incredible.",
        "rating": 5,
        "image_2": {image:"topgun7.jpeg", text:"Monica Barbaro AKA Phoenix. Also cooler than me or you",alt:"Monica Barbaro AKA Phoenix sitting in front of a fighter jet"},
        "image_1": {image:"topgun6.jpg", text:"Miles Teller as Rooster. Cooler than me or you",alt:"Miles Teller as Rooster in his flight gear"},
        "image_3": {image:"topgun5.jpeg", text:"Yep, he's actually in a real fighter jet",alt:"Tom Cruise in a fighter jet"},
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"We'll just cut to the chase - Top Gun Maverick is one of the best movies we've ever seen, and we're " +
            "already looking forward to seeing it again. If you don't know what it's about you must be from another planet," +
            " but just in case - it's the sequel to Top Gun, a 1980s action movie all about fighter pilots in the US Navy's 'Top Gun' training program. This new film," +
            " an amazing 36 years after the original, is also an action movie all about fighter pilots in the US Navy's 'Top Gun' training program. All caught up?",
        "paragraph_2": () => (
        <span>:"This movie reminded us of Star Wars The Force Awakens - the relatively recent movie which kickstarted the" +
            " Star Wars franchise again. The Force Awakens is very like the original Star Wars - it's about a youngster on a" +
            " desert planet who discovers she's a Jedi, it has some of the same characters as the original, and even ends" +
            " quite like the original movie, with a really big spaceship being blown up. We're not going to give any spoilers" +
            " for Top Gun Maverick, but it's also very like the original, with some scenes almost exactly the same - but " +
            "is also new enough to make it worth seeing. It's clever filmmaking - just the right amount of nostalgia" +
            " updated with new characters, story and technology."</span>),
        "paragraph_3":"What makes it so good? It's a really nice mix of action, drama, humour and does a great job building up the " +
            " tension throughout the movie, leading to its fantastic finish. A lot has been said in the build up to this movie" +
            " about how the fighter jet scenes were actually filmed with the actors in real fighter jets, really up in the air," +
            " as oppose to green-screen trickery. This makes the film even more enjoyable to watch, knowing that they've put" +
            " a lot of money and effort into making a great movie - and that's what it is, a big event movie that lives up" +
            " to the hype and keeps you entertained right the way through.",
        "paragraph_4":"",
        "paragraph_5":"The movie's star and hero is of course Tom Cruise, but he doesn't hog the limelight, there's plenty of " +
            " other good characters who get decent screentime, including Miles Teller as Rooster, Goose's son from the original movie, Monica Barbaro" +
            " as Phoenix, the only female pilot in the movie and without spoiling anything, a great appearance by Val Kilmer." +
            " We know from the first movie that Goose died (that's not a spoiler, it was 36 years ago!) so as you're watching," +
            " it is in the back of your mind - will anyone die this time around?",
        "paragraph_6":"We can't say any more as we really don't want to spoil anything, just know that we were very impressed" +
            " by this movie and glad that this type of movie is being made in this Marvel and Netflix era. This is an" +
            " old school big loud action movie with a lot of heart, that needs to be seen in a cinema with a huge" +
            " screen and great speakers. Enjoy!",
        "director": "Joseph Kosinski (Tron Legacy, Oblivion, Only the Brave)",
        "main_cast": "Tom Cruise, Miles Teller, Jennifer Connolly, Jon Hamm, Monica Barbaro, Val Kilmer",
        "summary": "Everything we hoped it would be, one of the best movies we've ever seen.",
        "diversity": [
            {"question":"White guy the main character?","answer": "Yes - Tom Cruise.","className": "redBox"},
            {"question":"Mainly male characters?","answer":"Yes, only a few women.","className": "redBox"},
            {"question":"Any strong female characters?","answer":"Yes - Monica Barbaro plays a fighter pilot." +
                    " and Jennifer Connolly plays a bar owner who knows her way around a sailboat.","className": "greenBox"},
            {"question":"Is it a diverse cast?","answer":"Sort of. All the main characters are white, but there are other ethnicities in strong roles.","className": "greenBox"},
            {"question":"Much LGBTQ+ representation?","answer":"Not that we could see. Make what you will of the beach scene!","className": "redBox"}],
        "date": "17/May/22",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://bsky.app/profile/andyosullivan.bsky.social"},
        "wifereview": "",
        "kidsreview": "",
        "short": false,
        "image_sources": "https://skydance.com/film/top-gun-maverick/"
    },
    {
        "name": "Dr. Strange in the Multiverse of Madness Review",
        "movie": "dr-strange-multiverse-of-madness",
        "subtitle": "More Marvel fun.",
        "rating": 4.5,
        "image_1": {image:"wanda.jpg", text:"Elizabeth Olson just floating over some candles"},
        "image_2": {image:"drstrange.jpg", text:"Dr. Strange having a bad day"},
        "image_3": "",
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1":"Yes, it's another Way Too Short Teenage Movie Reviews!",
        "paragraph_2": () => (
        <span>: "Very good movie, I hadn’t seen the first Dr.Strange yet and I still understood most of it, but my father needed to explain who Christine was.
            It was nonstop so everything just kept going and when you thought it was gonna be calm for a minute.... u were wrong lol, there was more. I loved how some of the characters weren’t who u thought they were gonna be so it was kinda just throwing curveballs at u the whole time.  And as the name suggests it stretches into the multiverse and the other Dr.Strange(s) with there completely different personalities and characteristics, I expected them to all be the same."</span>),
        "director": "Sam Raimi (The guy who directed the Tobey Maguire Spiderman movies!)",
        "main_cast": "Benedict Cumberbatch, Elizabeth Olson, Xochitl Gomez",
        "paragraph_3": "",
        "paragraph_4": "",
        "paragraph_5": "",
        "paragraph_6": "",
        "summary": "It's really good, go to the toilet before you watch it, it's really long!",
        "diversity": [
            {"question":"White guy the main character?","answer": "Yes - Benedict Cumberbatch.","className": "redBox"},
            {"question":"White guy saving the locals?","answer":"More like white guy saves the MULTIVERSE!","className": "redBox"},
            {"question":"Mainly male characters?","answer":"No, there's decent mix.","className": "greenBox"},
            {"question":"Any strong female characters?","answer":"Absolutely - Wanda is amazing in the movie, as are some" +
                    " other female characters that we don't want to spoil","className": "greenBox"},
            {"question":"Is it a diverse cast?","answer":"Mostly","className": "greenBox"},
            {"question":"Much LGBTQ+ representation?","answer":"A small bit - one character (no spoilers remember!) is shown to have two 'Moms'","className": "greenBox"}],
        "date": "17/May/22",
        "reviewer": {name:"M O'Sullivan",twitter:""},
        "wifereview": "",
        "kidsreview": "",
        "short": false,
        "image_sources": "https://press.disney.co.uk/press-kit/doctor-strange-in-the-multiverse-of-madness"
    },
    {
        "name": "Dune Review",
        "movie": "dune",
        "subtitle": "See on the biggest screen you can find.",
        "rating": 5,
        "image_1": {image:"dune001.jpg", text:"Josh Brolin & Oscar Isaac"},
        "image_2": {image:"theone.jpg", text: "Timothée Chalamet"},
        "image_3": "",
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "paragraph_1": "Dune is an epic science fiction movie based on a famous 1965 book by Frank Herbert that's regarded by many as " +
        "one of, if not the, best science fiction books ever written. Set about 8000 years in the future, it's a " +
        "story about a power struggle between rival Houses over the planet Arrakis - home of 'spice', the most expensive" +
        " substance in the universe.",
        "director": "Denis Villeneuve (Arrival, Blade Runner 2049, Sicario)",
        "main_cast": "Timothée Chalamet, Rebecca Ferguson, Oscar Isaac, Stellan Skarsgard, Zendeya, Jason Momoa and many more!",
        "paragraph_2": () => (
        <span>: "Everything about this movie is large-scale - the visuals, the sounds, the massive cast of great actors - it's" +
        " a feat of film-making and one of the best we've seen in quite a while. We luckily saw it in a cinema, where it should be" +
        " experienced. On the iPhone while eating the cornflakes in the morning just wouldn't do it justice."</span>),
        "paragraph_3": "The run-time is also large-scale, almost three hours, but it doesn't feel like it's too long" +
        " with events nicely paced and plenty of action. It takes its time in places - plenty of lingering shots of" +
        " immense spaceships, alien planets and manages to look and feel more real than typical CGI. " +
        "Note aswell that it's 'Part 1' of the story - and covers about half of the book, " +
        "so don't be surprised when it ends with obviously much more story left to come.",
        "paragraph_4": "If we have one quibble with the movie, is that it's occasionally difficult to follow what's going on" +
        " - it's not a film that dumbs things down for the audience. For me however, that just means I'll enjoy re-watching it again" +
        " and seeing things I didn't catch the first time.",
        "paragraph_5": "For anyone who hasn't read the book, some things may seem familiar from other books you've read" +
        " or movies you've seen - because Dune has influenced many works for decades. " +
        "Young hero in the desert - Star Wars? Dune did it " +
        " first! 'The One' prophesied to save us all - not the Matrix, but Dune! And if great Houses embroiled in a power " +
        "struggle, full of battles, betrayals and mystery sounds like Game of Thrones, Dune also did that first! Never mind" +
        " the Starks, House Atreides were our heroes from when we read the book as kids!",
        "summary": "An incredible movie, it's a grown up Star Wars - not for kids, it's a dark story where people die but" +
        " has so much wonder and scale of scope and ambition. Easily one of the best films we've seen in years.",
        "diversity": [
            {"question":"White guy the main character?","answer": "Yep - Timothée Chalamet.","className": "redBox"},
            {"question":"White guy saving the locals?","answer":"Yes - this is very much a Chosen One story, " +
            "however without wanting to spoil anything, " +
            " the book series as a whole is a cautionary take on following messiah type leaders, so " +
            " we'll let them away with it.","className": "greenBox"},
            {"question":"Mainly male characters?","answer":"Yep - very few women.","className": "redBox"},
            {"question":"Any strong female characters?","answer":"Yes - Rebecca Ferguson and Charlotte Rampling play members of" +
        " the 'Bene Gesserit' - a powerful female only group.","className": "greenBox"},
            {"question":"Is it a diverse cast?","answer":"Mostly, there are plenty of non-white actors.","className": "greenBox"},
            {"question":"Much LGBTQ+ representation?","answer":"None that we could see.","className": "redBox"}],
        "date": "15/Nov/21",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://bsky.app/profile/andyosullivan.bsky.social"},
        "wifereview": "No interest in seeing that",
        "kidsreview": "You can go see that one with one of your friends",
        "short": false,
        "image_sources": "https://www.warnerbros.co.uk/movies/dune"
    },
    {
        "name": "Jungle Cruise Review",
        "movie": "junglecruise",
        "rating": 3,
        "subtitle": "Family Fun",
        "image_1": {image:"junglecruise.jpeg", text:""},
        "image_2": {image:"junglecruise2.jpeg", text:""},
        "image_3": "",
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "director": "Jaume Collet-Serra (House of Wax, Unknown, Run all Night)",
        "main_cast": "Emily Blunt, Dwayne \"Do you smell what the Rock is cookin?\" Johnson, Jack Whitehall.",
        "paragraph_":  "Jungle Cruise is a family movie made by Disney, based on a themepark ride that we vaguely" +
        " remember going on years ago. The movie isn't as forgettable - it's" +
        " silly but fun, an old-school family adventure movie with some great actors, set in ... a jungle.",
        "paragraph_2": () => (
        <span>:  "This movie is one for the kids, a harmless adventure movie set in Victorian times with enough action and jokes" +
        " to keep every entertained for a couple of hours. The Rock is as good as always, a great mixture of" +
        " action hero and charm, Emily Blunt likewise, as an adventurous scientist in a man's world and Jack Whitehall" +
        " plays the comic relief as her overly-posh brother. "</span>),
        "paragraph_3": "Germans are rarely a bad idea as the adversaries in movies and Jungle Cruise serves up" +
        " Jesse Plemons who plays an over-the-top German Prince, hell bent on beating our heroes to a mythical tree" +
        " which has the ability to heal any wound. There are some surprises and other characters that we won't spoil," +
        " all leading to plenty to keep the action going.",
        "paragraph_4": "",
        "paragraph_5": "",
        "summary": "Good family fun, one for a movie night with the kids. It's probably a bit scary in places for some" +
        " young kids but it's not too bad. Kind of a watered down Indiana Jones movie.",
        "diversity": [
            {"question":"White guy the main character?","answer": "Nope.","className": "greenBox"},
            {"question":"White guy saving the locals?","answer":"No, though Emily Blunt kind of is the white British colonial type" +
            " coming in to save the day.","className": "greenBox"},
            {"question":"Mainly male characters?","answer":"Yep - very few women.","className": "redBox"},
            {"question":"Any strong female characters?","answer":"Yes - Emily Blunt plays a great character, strong willed and" +
            " easily a match for the Rock.","className": "greenBox"},
            {"question":"Is it a diverse cast?","answer":"Mostly, there are plenty of non-white actors.","className": "greenBox"},
            {"question":"Much LGBTQ+ representation?","answer":"It's heavily implied" +
                    " that Jack Whitehall's character is gay, though it's not confirmed.","className": "greenBox"}],
        "date": "15th November '21",
        "reviewer": {name:"Andy O'Sullivan",twitter:"https://bsky.app/profile/andyosullivan.bsky.social"},
        "wifereview": "I'm going to bed, this is nonsense",
        "kidsreview": "I saw it in the cinema, you have to watch it it's so good",
        "short": false,
        "image_sources": "https://dmedmedia.disney.com/disney-plus/jungle-cruise/images"

    },
    {
        "name": "Spider-Man: No Way Home Review",
        "movie": "spiderman-no-way-home",
        "rating": 5,
        "subtitle": "With great power comes great responsibility",
        "image_1": {image:"spider.jpg", text:""},
        "image_2": "",
        "image_3": "",
        "image_4": "",
        "image_5": "",
        "image_6": "",
        "director": "Jon Watts (Spider-Man: Homecoming, Spider-Man: Far From Home)",
        "main_cast": "Tom Holland, Zendeya, Bendict Cumberbatch",
        "paragraph_1": "Welcome to the first of our Way Too Short Teenage Movie Reviews. They're from our kids" +
        " who, although never have their phone out of their hands, really have to be persuaded to write a review!",
        "paragraph_2": () => (
        <span>: "I saw the new Spider-man movie in the cinema with my Auntie, who hasn't seen any" +
        " of the other ones with Tom Holland in them. She thought it was a great movie and she really liked it." +
        " I absolutely loved it. In my opinion, because of the events in the movie, I like the second one better" +
        " but only by a tiny amount. I would definitely recommend this if you likes the other Marvel or Spider-man movies." +
        " .Five stars lol."</span>),
        "paragraph_3": "",
        "paragraph_4": "",
        "paragraph_5": "",
        "summary": "Go see it.",
        "diversity": [
            {"question":"White guy the main character?","answer": "Yep.","className": "redBox"},
            {"question":"White guy saving the locals?","answer":"Not that sort of movie.","className": "greenBox"},
            {"question":"Mainly male characters?","answer":"Yep.","className": "redBox"},
            {"question":"Any strong female characters?","answer":"Yes - Aunt May.","className": "greenBox"},
            {"question":"Is it a diverse cast?","answer":"Mostly, there are plenty of non-white actors.","className": "greenBox"},
            {"question":"Much LGBTQ+ representation?","answer":"None that we could see.","className": "redBox"}],
        "date": "31/01/2022",
        "reviewer": {name:"M O'Sullivan",twitter:""},
        "wifereview": "You can take the girls to that one.",
        "kidsreview": "See above!",
        "short": true,
        "image_sources": "https://www.sonypictures.com/movies/spidermannowayhome"
    }
];
